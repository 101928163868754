.quiz-cs {
  display: flex;
  gap: 8px;
  overflow: scroll;
  margin: 0 -16px;
  flex-shrink: 0;
  padding-right: 16px;

  &::-webkit-scrollbar {
      display: none;
  }
}

.quiz-cs-item {
    height: 44px;
    border: 1px solid #4eacbc;
    border-radius: 14px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    gap: 10px;
    color: #4eacbc;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;

    &.quiz-cs-item-selected {
        background: rgba(103, 237, 255, 0.18);
        border: 1px solid #67EDFF;
        color: #67EDFF;
    }

    &:first-child {
        margin-left: 16px;
    }

    img {
        width: 28px;
        height: 28px;
    }

}
