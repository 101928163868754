.quiz-lobby-card {
    margin-top: 60px;

    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 24px;
        right: 24px;
        height: 4px;
        background: rgba(103, 237, 255, 0.25);
        border-radius: 8px;
    }
}

.quiz-lobby-title {
    margin-top: 16px;

    font-size: 18px;
    line-height: 25px;
}

.quiz-lobby-timer {
    margin-top: 4px;

    font-size: 32px;
    line-height: 40px;
}

.quiz-lobby-loader {
    --f7-preloader-size: 32px;
    --f7-preloader-color: #fff;
}
