.quiz-lobby-widgets-container {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
}

.quiz-lobby-widget {
    padding: 6px 6px 2px;
}

.quiz-lobby-widget-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
}

.quiz-lobby-widget-caption {
    color: rgba(255, 255, 255, 0.64);
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
}

.quiz-lobby-widget-life {
    width: 62px;

    span {
        width: 24px;
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        margin-left: 4px;
    }

    svg {
        fill: #ff545e;
    }

    &.quiz-lobby-no-more-life {
        color: #76787a;

        svg {
            fill: #76787a;
        }
    }
}

.quiz-lobby-widget-people {
    font-size: 16px;
    color: #fff;

    svg {
        margin-right: 8px;
    }

    .quiz-lobby-widget-body {
        justify-content: flex-end;

        span {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: -12px;
                top: 50%;
                margin-top: -3px;

                width: 6px;
                height: 6px;
                background-color: #48a24b;
                border-radius: 50%;
            }
        }
    }
}

.quiz-loader {
    position: absolute;
    top: calc(45% - var(--f7-preloader-size) / 2);
    left: calc(50% - var(--f7-preloader-size) / 2);
}

.quiz-question-card {
    margin-top: 16px;

    padding: 32px 24px;
    position: relative;

    & .quiz-question-question {
        font-size: 18px;
        line-height: 25px;
        min-height: 75px;
        margin-bottom: 16px;
    }

    & .quiz-question-cooldown {
        position: absolute;
        top: 0;
        left: 24px;
        right: 24px;
    }

    p {
        font-size: 16px;
        line-height: 22px;
        margin-top: 24px;
        margin-bottom: 0;
    }

    button {
        margin-top: 40px;
    }

}
