.quiz-start-bg {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 320px;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #181A20 100%);
    }
}

.quiz-start-container {
    padding-top: 320px;

    & .quiz-text-primary {
        line-height: 33px;

        & + .quiz-text-primary {
            margin-top: 8px;
        }
    }
}
