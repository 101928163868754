.quiz-result-caption-correct {
    color: #32D843;
}

.quiz-result-caption-incorrect {
    color: #F24242;
}

.quiz-result-cooldown {
    margin-top: 24px;
}

.quiz-result-container {
    margin-top: 24px;
}
