.quiz-option + .quiz-option {
    margin-top: 12px;
}

.quiz-option {
    --selected-color: #67EDFF;
    --success-bg-color: rgba(50, 216, 67, 0.25);
    --error-bg-color: rgba(242, 66, 66, 0.25);


    width: 100%;
    background-color: rgba(187, 203, 255, 0.15);
    border-radius: 16px;
    padding: 18px 50px 18px 18px;
    position: relative;
    text-align: left;
    border: 1px solid transparent;

    &::before {
        content: "";
        position: absolute;
        box-sizing: border-box;
        width: 24px;
        height: 24px;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 50%;
    }

    &.quiz-option-active {
        background-color: rgba(103, 237, 255, 0.15);
        border: 1px solid var(--selected-color);

        &::before {
            border-color: var(--selected-color);
        }

        &::after {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            background-color: var(--selected-color);
            border-radius: 50%;
        }
    }

    &.quiz-option-selected {
        &::before {
            content: url(../../assets/icon-success.svg);
            border: none;
        }
    }

    &.quiz-option-success {
        background-color: var(--success-bg-color);

        &::before {
            content: url(../../assets/icon-success.svg);
            border: none;
        }
    }

    &.quiz-option-error {
        background-color: var(--error-bg-color);

        &::before {
            content: url(../../assets/icon-fail.svg);
            border: none;
        }
    }

    &.quiz-option-invisible {
        color: transparent;
    }

}
