.quiz-offline-question-container {
    margin-bottom: 24px;
    width: 100%;
    padding: 0 24px;
}

.quiz-tip {
    margin-top: 24px;
    width: 100%;
    padding: 8px 30px 8px 8px;
    border: 1px solid #20263D;
    border-radius: 16px;
    display: flex;
    align-items: flex-start;

    & > svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        flex-shrink: 0;
    }
}

.quiz-question-card-reading {
    & .quiz-question-question {
        margin: 142px 0;
    }

    & .quiz-question-variants-container {
        display: none;
    }
}

.quiz-offline-mute {
    color: #4E77FB;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    svg {
        fill: #4E77FB;
    }
}
