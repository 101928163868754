@import 'node_modules/framework7/framework7.less';
@includeIosTheme: false;
@includeAuroraTheme: false;
@import 'node_modules/framework7/components/list/list.less';
@import 'node_modules/framework7/components/input/input.less';
@import 'node_modules/framework7/components/text-editor/text-editor.less';
@import 'node_modules/framework7/components/sheet/sheet.less';
@import 'node_modules/framework7/components/dialog/dialog.less';
@import 'node_modules/framework7/components/navbar/navbar.less';
@import 'node_modules/framework7/components/swiper/swiper.less';
@import 'node_modules/framework7/components/toast/toast.less';
@import 'node_modules/framework7/components/checkbox/checkbox.less';
@import 'node_modules/framework7/components/popup/popup.less';
@import 'node_modules/framework7/components/toolbar/toolbar.less';

html {
    overflow-y: hidden;
}

html,
body {
    position: fixed;
    font-family: 'Manrope', sans-serif;
}

* {
    user-select: none;
}

input[type="text"]:not([readonly]):not([disabled]) {
    user-select: initial;
}

#root {
    /* inputs */
    --f7-input-height: 56px;
    --f7-input-outline-height: 56px;
    --f7-list-bg-color: none;
    --f7-label-text-color: rgba(255, 255, 255, 0.64);
    --f7-input-placeholder-color: rgba(255, 255, 255, 0.64);
    --f7-input-text-color: #f9f9f9;
    --f7-list-item-padding-vertical: 4px;
    --f7-input-outline-border-radius: 16px;
    --f7-list-margin-vertical: 24px;

    .list {
        margin: var(--f7-list-margin-vertical) 0 0;

        .item-inner {
            height: 64px;
        }
    }

    .item-input-outline.item-input-with-value,
    .item-input-outline.item-input-focused {
        .item-floating-label {
            transform: scale(1) translateY(130%);
        }

        input {
            transform: translateY(8px);
        }
    }

    .item-input-wrap {
        background-color: rgba(255, 255, 255, 0.08);
        border-radius: var(--f7-input-outline-border-radius);

        &::after {
            display: none;
        }
    }

    /* DIALOG */

    --f7-dialog-width: calc(100% - 32px);
    --f7-dialog-border-radius: 32px;
    --f7-dialog-bg-color: var(--f7-theme-color-bg-color);
    --f7-dialog-inner-padding: 16px 24px 24px;
    --f7-dialog-text-align: center;

    --f7-dialog-button-font-size: 14px;
    --f7-dialog-button-height: auto;

    /* Checkbox */
    --f7-checkbox-size: 24px;
    --f7-checkbox-border-radius: 8px;
    --f7-checkbox-border-width: 1px;
    --f7-checkbox-inactive-color: rgba(255, 255, 255, 0.5);
    --f7-checkbox-icon-color: #fff;
    --f7-checkbox-active-color: #4E77FB;

    .dialog-buttons {
        height: auto;
        padding: 0 24px 24px;
    }

    .popup {
        position: fixed;

        --f7-toolbar-height: 96px;

        .toolbar {
            background: #0e1420;

            &::after { display: none; }
        }
    }

}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Prevent vertical overscrolling on iOS */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

:focus {
    outline: none;
}

button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
}

.page {
    /* Safari doesn't support `overflow: x y;` shortcut */
    overflow-x: hidden;
    overflow-y: auto;
}

.page-content {
    --bottom-safe-inset: calc(var(--f7-page-toolbar-bottom-offset,0px) + var(--f7-safe-area-bottom) + var(--f7-page-content-extra-padding-bottom,0px));
    padding-bottom: calc(22px + var(--bottom-safe-inset));
    overflow-y: scroll !important;
}


.quiz-font-400 { font-weight: 400; }
.quiz-font-500 { font-weight: 500; }
.quiz-font-600 { font-weight: 600; }
.quiz-font-700 { font-weight: 700; }
.quiz-font-800 { font-weight: 800; }

.quiz-text-primary {
    font-size: 24px;
    line-height: 32px;
}

.quiz-text-secondary {
    font-size: 20px;
    line-height: 24px;
}

.quiz-text-regular {
    font-size: 16px;
    line-height: 22px;
}

.quiz-text-caption {
    font-size: 14px;
    line-height: 20px;
}

.quiz-text-small {
    font-size: 12px;
    line-height: 16px;
}

.quiz-text-primary,
.quiz-text-regular,
.quiz-text-caption {
    text-align: center;
}

.quiz-text-color-primary {
    color: var(--f7-theme-color);
}

.quiz-flex {
    display: flex;
    flex-direction: column;
}

.quiz-container-100 {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
}

.quiz-container,
.quiz-popup-container,
.quiz-top-text-container,
.quiz-popup-top-text-container
{
    width: 100%;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
}

.quiz-popup-container {
    padding: 0 24px;
}

.quiz-top-text-container {
    padding: 24px 16px 0;
}

.quiz-popup-top-text-container {
    padding: 24px 24px 0;
}

.quiz-popup-button-container {
    width: 100%;
    padding: 16px 16px 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > *:not(:first-child) {
        margin-left: 16px;
    }
}

.quiz-bottom-container {
    width: 100%;
    flex-grow: 1;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    align-items: stretch;
    box-sizing: border-box;
    z-index: 1;
}

.quiz-vertical-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.quiz-card {
    height: auto;
    width: 100%;
    padding: 16px 24px;
    background: var(--f7-theme-color-bg-color);
    border-radius: 32px;
    text-align: center;
}

.quiz-card-icon {
    margin-top: -8px;
    text-align: center;
    margin-bottom: 20px;

    & > img {
        width: 80px;
    }
}

.quiz-link {
    text-decoration: none;
    cursor: pointer;
}

.quiz-link,
.color-cyan {
    color: #67EDFF;
}

.color-caption {
    color: #BBCBFF;
}

.color-light-gray {
    color: rgba(255, 255, 255, 0.64);
}

.w-full {
    width: 100%;
}

.mt-8 {
    margin-top: 8px;
}

.mt-12 {
    margin-top: 12px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-24 {
    margin-top: 24px;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.block-between {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-uppercase {
    text-transform: uppercase;
}
