.quiz-settings {

    button {
        margin-top: 16px;
    }

    button:first-of-type {
        margin-top: 32px;
    }
}
