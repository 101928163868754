.quiz-settings-promo-code-label {
    margin-top: 24px;

    opacity: 0.64;
}

.quiz-settings-promo-code-span {
    margin-top: 12px;

    background-color: rgba(255, 255, 255, 0.08);
    border-radius: var(--f7-input-outline-border-radius);
    line-height: 56px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 12px;
}

.quiz-settings-promo-code-copy,
.quiz-settings-promo-code-share
{
    margin: 16px auto 0;
    position: relative;
    padding: 6px 16px 6px 40px;
    border: 1px solid rgba(255, 255, 255, 0.32);
    border-radius: 16px;
    box-sizing: border-box;
}

.quiz-settings-promo-code-copy::before {
    content: url(../../../assets/icon-copy.svg);
    position: absolute;
    left: 16px;
    top: 6px;
}

.quiz-settings-promo-code-share::before {
    content: url(../../../assets/icon-share.svg);
    position: absolute;
    left: 10px;
    top: 4px;
}


.quiz-settings-promo-code-dialog {
    --f7-dialog-inner-padding: 32px 0 40px;
}

.quiz-settings-promo-code-dialog-close {
    position: absolute;
    top: 16px;
    right: 16px;
}
