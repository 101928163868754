.quiz-win-icon-container {
    text-align: center;
    margin-top: -32px;
}

.quiz-win-title {
    font-size: 20px;
    line-height: 27px;
}


