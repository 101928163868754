.quiz-question-variants-container {
    width: 100%;
    min-height: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.quiz-question-warning {
    height: 36px;
    border-radius: 8px;
    background-color: rgba(233, 86, 86, 0.12);
    line-height: 36px;
    text-align: center;
    color: #ff545e;
    font-size: 14px;

    margin-bottom: 8px;
}
