.quiz-settings {

    button {
        margin-top: 16px;
    }

    button:first-of-type {
        margin-top: 32px;
    }

    .quiz-settings-notifications {
        margin-top: 24px;

        background: linear-gradient(180deg, rgba(103, 237, 255, 0.18) 0%, rgba(103, 237, 255, 0) 100%);
        border: 2px solid #67EDFF;
        box-sizing: border-box;
        border-radius: 32px;
        padding: 8px 24px 24px;
        color: #fff;
        text-align: center;

        .quiz-settings-notifications-control {
            margin-top: 16px;
            display: block;
        }

        .quiz-settings-notifications-checkbox {
            margin-right: 16px;
        }
    }
}
