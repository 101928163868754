.quiz-offline-list-title {
    margin-top: 32px;
    text-transform: uppercase;
}

.quiz-offline-list-item {
    margin-top: 24px;
}

.quiz-card-list {
    overflow: auto;
    margin: 0 -16px;
    padding: 0 16px;
}
