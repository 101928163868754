.quiz-button,
#root .dialog-button
{
    width: 100%;
    border-radius: 16px;
    padding: 18px 0;
    text-align: center;
    border: none;
    color: #fff;
    background-color: #4E77FB;
    box-sizing: border-box;

    &:disabled {
        background-color: #8D8E8F;
    }

    &.quiz-button-white {
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        color: #4E77FB;
        background-color: #F1F5FF;
    }

    &.quiz-button-block {
        display: flex;
        padding: 0;
        height: 58px;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

}

.quiz-button-big {
}

.quiz-button-outline {
    font-weight: 600;
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.32);
}

.quiz-button-loading {
    padding: 0;
    height: 56px;
    --f7-preloader-size: 34px;
    --f7-preloader-color: #fff;
}
