.quiz-finish-container {
    padding: 16px;
}

.quiz-finish-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    border: 2px solid #67EDFF;
    border-radius: 32px;
    background: linear-gradient(180deg, rgba(103, 237, 255, 0.18) 0%, rgba(103, 237, 255, 0) 100%);

    &__error {
        border-color: #F24242;
        background: linear-gradient(180deg, rgba(242, 66, 66, 0.18) 0%, rgba(242, 66, 66, 0) 100%);
    }

    &__success {
        border-color: #32D74B;
        background: linear-gradient(180deg, rgba(50, 215, 75, 0.18) 0%, rgba(24, 69, 44, 0) 100%);
    }
}

.quiz-finish-emotion {
    width: 80px;
    height: 80px;
}

.quiz-finish-card-text {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
}

.quiz-finish-leader-list {
    width: 100%;
    background: #20263D;
}

.quiz-finish-leader-item {
    width: 100%;
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 16px;


    & > svg {
        flex-shrink: 0;
        margin-left: auto;
        margin-right: 3px;
    }

    .quiz-finish-leader-item-result {
        flex-shrink: 0;
        width: 35px;
    }
}
