.quiz-cooldown {
    --f7-preloader-size: 20px;
    --f7-preloader-color: #9B9B9B;

    --transition-duration: 150ms;
    text-align: center;
}

.quiz-cooldown-progress-bar {
    width: 100%;
    height: 4px;
    background-color: rgba(155, 155, 155, 0.25);
    position: relative;
    border-radius: 8px;

    transition: background-color var(--transition-duration);

    div {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #9B9B9B;
        border-radius: 11px;

        transition: background-color, box-shadow var(--transition-duration);
    }

    & + .quiz-cooldown-timer {
        color: #9B9B9B;
    }

    &.quiz-cooldown-success {
        background-color: rgba(50, 215, 75, 0.25);

        div {
            background-color: #32D74B;
            box-shadow: 0px 0px 4px #32D74B, 0px 0px 24px rgba(50, 215, 75, 0.65);
        }

        & + .quiz-cooldown-timer {
            color: #32D74B;
        }
    }


    &.quiz-cooldown-active {
        background-color: rgba(103, 237, 255, 0.25);

        div {
            background-color: #67EDFF;
            box-shadow: 0px 0px 4px #67EDFF, 0px 0px 24px rgba(103, 237, 255, 0.65);
        }

        & + .quiz-cooldown-timer {
            color: #67EDFF;
        }
    }

    &.quiz-cooldown-warning {
        background-color: rgba(242, 157, 66, 0.25);

        div {
            background-color: #F29D42;
            box-shadow: 0px 0px 4px #E89926, 0px 0px 24px rgba(232, 153, 38, 0.65);
        }

        & + .quiz-cooldown-timer {
            color: #F29D42;
        }
    }

    &.quiz-cooldown-error {
        background-color: rgba(242, 66, 66, 0.25);

        div {
            background-color: #F24242;
            box-shadow: 0px 0px 4px #F24242, 0px 0px 24px rgba(242, 66, 66, 0.65);
        }

        & + .quiz-cooldown-timer {
            color: #F24242;
        }
    }
}

.quiz-cooldown-timer {
    font-size: 12px;
    line-height: 20px;
    text-align: center;

    transition: color var(--transition-duration);
}
