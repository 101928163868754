.quiz-warning {
    border-radius: 8px;
    background-color: rgba(238, 123, 22, 0.12);
    padding: 10px 0;
    text-align: center;
    color: var(--f7-theme-color);

    margin: 16px 0;

    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
}
