.quiz-welcome {
    --swiper-theme-color: #E7E7E9;

    height: 100%;



    & .swiper-slide {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        text-align: center;
        padding: 0 24px;

        & > svg {
            margin-top: 40px;
            width: 80px;
            height: 80px;
        }

        & .quiz-text-primary {
            margin-top: 29px;
            font-weight: 700;
        }

        p {
            margin-top: 24px;
            margin-bottom: 0;
            line-height: 20px;
            font-weight: 500;
        }

        & .quiz-bottom-container {
            align-items: center;
        }

        button {
            width: 240px;
            position: relative;

            svg {
                position: absolute;
                top: 16px;
                right: 16px;
            }
        }
    }

    & .swiper-pagination-bullets {
        bottom: 90px;

        & .swiper-pagination-bullet {
            width: 6px;
            height: 6px;
            opacity: 0.5;
            background: var(--swiper-theme-color);

            &.swiper-pagination-bullet-active {
                opacity: 1;
            }
        }
    }
}
