.quiz-main-title {
    margin-top: 32px;

    text-transform: uppercase;
}

.quiz-main-card {
   margin-top: 24px;
   padding-top: 0;

   &__title {
       height: 48px;
       background: linear-gradient(90deg, rgba(18, 22, 38, 0) 0%, rgba(78, 119, 251, 0.35) 50%, rgba(18, 22, 38, 0) 100%);
       line-height: 48px;
       font-size: 20px;
       overflow: hidden;
       text-overflow: ellipsis;
   }

    &__description {
        margin-top: 16px;
    }

    &__startTime {
        margin-top: 24px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;
        line-height: 24px;
    }

    &__button {
        margin-top: 24px;
    }
}

.quiz-main-top-bar {
    margin-top: 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &__settings {
        width: 40px;
        height: 40px;
        border-radius: 16px;
        background: var(--f7-theme-color-bg-color);
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;

        & svg {
            width: 32px;
            height: 32px;
        }

    }
}

.quiz-main-time {
    margin-bottom: 24px;

    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.02em;
}

.quiz-main-settings {
    margin: 24px auto 0;

    width: 156px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
}

.quiz-main-no-quiz {
    & > svg {
        display: block;
        margin: auto;
    }

    & > span {
        margin-top: 24px;
        display: block;
        text-transform: uppercase;
    }

    & > .quiz-main-settings {
        margin-top: 28px;
    }
}
