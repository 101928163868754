.quiz-offline-card {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 75%;
        background: linear-gradient(90deg, rgba(18, 22, 38, 0) 0%, rgba(78, 119, 251, 0.35) 50%, rgba(18, 22, 38, 0) 100%);
    }
}

.quiz-offline-card-selected {
    border: 1px solid #5938DC;
    box-shadow: 0px 7px 30px rgba(28, 71, 154, 0.9);
}

.quiz-offline-card-title {
    z-index: 1;
    padding: 0 24px;
}

.quiz-offline-card-image {
  margin-top: 12px;
  padding: 120px 24px 24px;
  background-size: cover;
  text-align: left;
  background-color: var(--f7-theme-color-bg-color);
  z-index: 1;
  width: calc(100% + 48px);
  position: relative;

  &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(0,0,0,0) 30%, rgba(0,0,0,1) 100%);
  }

  div {
      position: relative;
  }
}

.quiz-offline-card-info-block {
    margin-top: 16px;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.quiz-offline-card-info-block-history {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;

    div {
        display: flex;
    }

    svg {
        width: 24px;
        height: 24px;
        margin-right: 4px;
    }
}

.quiz-offline-card-value {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 0.02em;
}

.quiz-offline-card-labels-container {
    position: absolute !important;
    left: 8px;
    top: 8px;
    display: flex;
    gap: 8px;

    & > div {
        padding: 5px;
        border-radius: 8px;
        line-height: 20px;
        display: flex;
        z-index: 2;
    }
}

.quiz-offline-card-label-new {
    background: #F44336;
}

.quiz-offline-card-label-rating {
    background: #677280;
}

.quiz-offline-card-label-rating-high {
    background: #34C759;
}



.quiz-offline-card-share {
    position: absolute;
    top: 16px;
    right: 24px;
    z-index: 2;
}
