.quiz-offline-top-bar {
    display: flex;
    align-items: center;
}

.quiz-offline-top-bar-caption {
    color: rgba(255, 255, 255, 0.64);
}

.quiz-offline-top-bar-value {
    margin-left: 5px;
    vertical-align: top;
    display: inline-block;
}

.quiz-offline-top-bar-attempts {
    background: linear-gradient(180deg, rgba(103, 237, 255, 0.18) 0%, rgba(103, 237, 255, 0) 100%);
    border: 1px solid #67EDFF;
    border-radius: 4px;
    padding: 8px;

    &__icons {
      display: flex;
      justify-content: space-between;
      height: 24px;
    }
}

.quiz-offline-top-bar-points {
    margin-left: 16px;
}

.quiz-offline-top-bar-recent {
    margin-left: auto;
    width: 32px;
    height: 32px;
}

.quiz-offline-top-bar-settings {
    margin-left: 24px;
    width: 32px;
    height: 32px;
}

